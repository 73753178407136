import React from "react"
import ContentContainer from "../../../../templates/ContentContainer"
import NewsLayout from "../NewsLayout"
import NewsNestedHelmet from "./NewsNestedHelmet"
import NewsNestedSidebar from "./NewsNestedSidebar"
import NewsNestedMediaFeed from "./NewsNestedMediaFeed"

interface NewsNestedMediaPageProps {
    title: string
    pathname: string
}

const NewsNestedMediaPage: React.FC<NewsNestedMediaPageProps> = ({
    title,
    pathname,
}) => {
    return (
        <NewsLayout>
            <NewsNestedHelmet pathname={pathname} />
            <div className="bg-horizen-dark-blue h-[140px] lg:h-[200px]" />
            <ContentContainer>
                <div className="grid lg:grid-cols-[7fr,3fr] gap-6">
                    <NewsNestedMediaFeed title={title} />
                    <NewsNestedSidebar />
                </div>
            </ContentContainer>
        </NewsLayout>
    )
}

export default NewsNestedMediaPage
