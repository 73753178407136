import React from "react"
import type { PageProps } from "gatsby"
import NewsNestedMediaPage from "../../sections/OldDesign/news/Nested/NewsNestedMediaPage"

const MediaMention: React.FC<PageProps> = ({ location }) => {
    return (
        <NewsNestedMediaPage
            pathname={location.pathname}
            title="Media Mentions"
        />
    )
}

export default MediaMention
