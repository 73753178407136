import React from "react"
import { useQuery } from "react-query"
import Loading from "../../../../components/Loading"
import MediaMention from "../MediaMention"
import { RSSMiddleware } from "../middlewares"

interface NewsNestedMediaFeedProps {
    title: string
}

const NewsNestedMediaFeed: React.FC<NewsNestedMediaFeedProps> = ({ title }) => {
    const { data, isSuccess } = useQuery("news-nested-media-feed", () =>
        RSSMiddleware.getMediaMentionRSSs()
    )

    return (
        <section className="py-16">
            <h2 className="font-proxima text-3xl font-bold text-old-design-dark-blue uppercase">
                {title}
            </h2>
            {isSuccess ? (
                <>
                    <hr className="border-old-design-gray mt-6" />
                    <div className="grid">
                        {data.map((mention) => (
                            <MediaMention
                                key={mention.id}
                                mediaMentions={mention}
                            />
                        ))}
                    </div>
                </>
            ) : (
                <Loading />
            )}
        </section>
    )
}

export default NewsNestedMediaFeed
