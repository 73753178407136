import React from "react"
import { MediaMentionRSST } from "./types"

interface MediaMentionProps {
    mediaMentions: MediaMentionRSST
}

const MediaMention: React.FC<MediaMentionProps> = ({ mediaMentions }) => {
    return (
        <a
            href={mediaMentions.link}
            className="group py-5 border-old-design-gray border-b transition-colors"
        >
            <h6 className="font-proxima text-xs text-[#7e8ea8]">
                {mediaMentions.subTitle}
            </h6>
            <h5 className="font-proxima text-lg font-bold mt-1 group-hover:underline overflow-hidden [display:-webkit-box] [-webkit-line-clamp:2] [-webkit-box-orient:vertical]  [word-break:break-all]">
                {mediaMentions.title}
            </h5>
            <p className="font-proxima text-base text-old-design-dark-blue mt-1 group-hover:underline overflow-hidden [display:-webkit-box] [-webkit-line-clamp:2] [-webkit-box-orient:vertical]  [word-break:break-all]">
                {mediaMentions.description}
            </p>
        </a>
    )
}

export default MediaMention
